<template>
  <div id="app">
    <flipbook class="flipbook" :pages="pages"></flipbook>
  </div>
</template>

<script>
import Flipbook from 'flipbook-vue';

export default {
  name: 'App',
  components: {
    Flipbook,
  },
  data() {
    return {
      pages: [
        'https://mrozhost.pl/strona-0.jpg',
        'https://mrozhost.pl/strona-1.jpg',
        'https://mrozhost.pl/strona-2.jpg',
        'https://mrozhost.pl/strona-3.jpg',
        'https://mrozhost.pl/strona-4.jpg',
        'https://mrozhost.pl/strona-5.jpg'
      ],
    };
  },
};
</script>

<style>
.flipbook {
  width: 90vw;
  height: 90vh;
}
</style>
